/*@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

.App {
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100vw;
  height: 100vh;
  background-image: url('images/PXL_20220908_230450816.jpg');
  background-size: cover;
  background-position: center;
  font-family: "Anton";
  -webkit-text-stroke-color: black;

  color: white;

  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.titleText {
  /*font-family: "Open Sans", sans-serif;
  font-family: "Kanit";*/
  
  font-weight: 950;
  font-size: 7rem;
  -webkit-text-stroke-width: 0.3rem;

  padding-top: 20rem;
}

.subText {
  font-size: 3rem;
  -webkit-text-stroke-width: 0.15rem;
  padding-top: 2rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
